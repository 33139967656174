import React from 'react'

function InternationallyWorkComponents() {
  return (
    <section className='internationallyWork__section margin-t'>
        <div className='container'>
            <div className='text-center internationallyWork_header_section'>
                <h2>We work internationally</h2>
                <p>We have a global team and a diverse list of clients. No matter where you are located, we can work with you seamlessly.</p>
            </div>
            <div className='internationallyWork__boxes'>
                <div className='flages_boxes'>
                    <div className='d-flex'>
                        <img className="img-fluid" src="../images/australia_flages.png" />
                        <p>Australia</p>
                    </div>
                </div>
                <div className='flages_boxes'>
                    <div className='d-flex'>
                        <img className="img-fluid" src="../images/united_states_flages.png" />
                        <p>United States</p>
                    </div>
                </div>
                <div className='flages_boxes'>
                    <div className='d-flex'>
                        <img className="img-fluid" src="../images/switzerland_flags.png" />
                        <p>Switzerland</p>
                    </div>
                </div>
                <div className='flages_boxes'>
                    <div className='d-flex'>
                        <img className="img-fluid" src="../images/japan_flages.png" />
                        <p>Japan</p>
                    </div>
                </div>
                <div className='flages_boxes'>
                    <div className='d-flex'>
                        <img className="img-fluid" src="../images/uk_flages.png" />
                        <p>UK</p>
                    </div>
                </div>
                <div className='flages_boxes'>
                    <div className='d-flex'>
                        <img className="img-fluid" src="../images/canada_flags.png" />
                        <p>Canada</p>
                    </div>
                </div>
                <div className='flages_boxes'>
                    <div className='d-flex'>
                        <img className="img-fluid" src="../images/india_flags.png" />
                        <p>India</p>
                    </div>
                </div>
                <div className='flages_boxes'>
                    <div className='d-flex'>
                        <img className="img-fluid" src="../images/ireland_flages.png" />
                        <p>Ireland</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default InternationallyWorkComponents