import React from 'react';
import Navbars from './Navbars';
import BannerComponents from '../components/BannerComponents';
import DesignCardComponents from '../components/DesignCardComponents';
import TwoColComponent from '../components/TwoColComponent';
import ThreeColComponents from '../components/ThreeColComponents';
import TabComponents from '../components/TabComponents';
import PortfolioComponent from '../components/PortfolioComponent';
import AccordionComponents from '../components/AccordionComponents';
import BestfitComponents from '../components/BestfitComponents';
import TestimonialComponents from '../components/TestimonialComponents';
import PostComponents from '../components/PostComponents';
import WebDesignComponents from '../components/WebDesignComponents';
import PartnersLogoComponent from '../components/PartnersLogoComponent';
import SeoComponents from '../components/SeoComponents';
import InternationallyWorkComponents from '../components/InternationallyWorkComponents';

function FrontendLayout() {
  return (
    <>
        <SeoComponents />
        <BannerComponents />
        <DesignCardComponents />
        <TwoColComponent />
        <ThreeColComponents />
        <TabComponents />
        <PortfolioComponent />
        <AccordionComponents />
        <BestfitComponents />
        <WebDesignComponents />
        <TestimonialComponents />
        <PartnersLogoComponent />
        <InternationallyWorkComponents />
        <PostComponents />
    </>
  )
}

export default FrontendLayout;