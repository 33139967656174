import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BannerComponents from '../components/BannerComponents';
import SeoComponents from '../components/SeoComponents';

function AboutUsLayout() {
    const [aboutdata, setAboutdata] = useState([]);
    const [teamdata, setTeamdata] = useState([]);
    const pathName = window.location.pathname;
    const slugArr = pathName.split('/');
    let slugName;
    if(slugArr.length == 2){
      slugName = pathName.slice(1);
    }else{
      let slugArr = pathName.split('/', 2);
      slugName = slugArr.slice(1);
    }
    const getDesignApiData = async () => {       
        const res = await axios.get(`/pagecontent?page_slug=${slugName}`);
        setAboutdata(res.data.recive_data[0].two_column_image_content);
        setTeamdata(res.data.recive_data[1].three_column_image_content);
        console.log(res.data.recive_data[1].three_column_image_content); 
    }
    useEffect(() => {
        getDesignApiData();
    }, []);

    const aboutdataImg = aboutdata.image_details;
    return (
    <>
        <SeoComponents />
        <BannerComponents />
        <section className='aboutUs_twoCol__section margin-t-b'>
            <div className="container">
              <div className='text-center ex_header_section'>
                <h2>Who We Are</h2>
                <p>Leidsens Technology LLP is a premier web design and development firm with over 17 years of industry experience. We specialize in crafting visually stunning, high-performance websites that drive engagement and business growth. Our expertise spans across responsive website design, web and mobile applications, e-commerce solutions, branding, and graphic design. With a strong global presence, we have successfully collaborated with businesses of all sizes, delivering innovative and results-driven digital solutions.</p>
              </div>
              <div className='row ex_contentPart'>
                <div className='col-md-6'>
                    {
                        aboutdata?(<div className="fullWidth_content" dangerouslySetInnerHTML={{ __html: aboutdata.content }}></div>):null
                    }
                </div>
                <div className='col-md-6'>
                    { 
                        aboutdataImg?(<img src={aboutdataImg.image_path} className='img-fluid' alt="" /> ):null
                    }
                </div>
              </div>               
            </div>
        </section>
        <section className='ourteam__section margin-b'>
            <div className='container'>
                <div className='outTeamInner__section'>
                    <h2>Our Team </h2>
                    <h4>A Blend of Creativity and Expertise</h4>
                    <p>At Leidsens Technology LLP, our team is the backbone of our success. With 17 years of industry experience, we have built a strong foundation of skilled web designers, developers, and branding experts. Our team combines creativity, technical proficiency, and strategic thinking to craft high-quality digital solutions tailored to our clients' unique needs. With a passion for innovation and a commitment to excellence, we continuously push boundaries to deliver outstanding results, making us a trusted partner in the digital space.</p>
                    <div className='row'>
                        {
                            teamdata?(
                                teamdata.map( (post, index) => {
                                    const { image_details, title, sub_title, content } = post;
                                    return(
                                        <div className='col-12 col-md-4 col-lg-3 outTeam_ditails' key={index}>                                        
                                            {image_details?(<img src={image_details.image_path} className='img-fluid' alt="" />):null}
                                            {title?(<h5>{title}</h5>):null}
                                            {sub_title?(<p>{sub_title}</p>):null}
                                            {content?(<p>{content}</p>):null}
                                        </div>
                                    )
                                })                             
                            ):null
                        }                                              
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AboutUsLayout