import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BannerComponents from '../components/BannerComponents';
import SeoComponents from '../components/SeoComponents';

const ExpertiseLayout = () => {
  const [expertise, setExpertise] = useState([]);
  const [expertiseCard, setExpertiseCard] = useState([]);
  const pathName = window.location.pathname;
  const slugName = pathName.slice(1);
  const getApiData = async () => {
    const res = await axios.get(`/pagecontent?page_slug=${slugName}`);
    setExpertise(res.data.recive_data[0].two_column_image_content);
    setExpertiseCard(res.data.recive_data[1].three_column_image_content);
    // console.log(res.data.recive_data[1].three_column_image_content);  
  }
  useEffect(() => {
    getApiData();
  }, []);
var ex_data = expertise.image_details;
  return (
    <>
        <SeoComponents />
        <BannerComponents />
        <section className='expertise_section margin-t-b'>
            <div className="container">
              <div className='text-center ex_header_section'>
                <h2>WHY WORK WITH TEAM LEIDSENS?</h2>
                <p>Leidsens Technology LLP is a seasoned web design and development firm that has been delivering high-quality services to clients worldwide for over 17 years. They specialize in creating visually appealing and traffic-driving websites, offering services such as responsive website design, web and mobile applications, e-commerce solutions, branding, and graphic design.</p>
              </div>
              <div className='row ex_contentPart'>
                <div className='col-md-6'>
                  {
                      expertise?(<div className="fullWidth_content" dangerouslySetInnerHTML={{ __html: expertise.content }}></div>):null
                  }
                </div>
                <div className='col-md-6'>
                  {
                    ex_data?(<img src={ex_data.image_path} className='img-fluid' alt="" /> ):null
                  }
                </div>
              </div>               
            </div>
        </section>
        <section className='expertiseSecond_section margin-b'>
          <div className='container'>
              <div className='row expertiseCardBoxes'>
                {
                  expertiseCard?(
                    expertiseCard.map( (post, index) =>{
                      const { image_details, title, sub_title, content } = post;
                      return(
                        <div className='col-sm-6 expertiseCardBoxe' key={index}>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='cardIcon'>{image_details?(<img src={image_details.image_path} alt="" /> ):null}</div>
                              <h6 className='text-center'>{sub_title}</h6>
                              <h4 className='text-center'>{title}</h4>
                              <p>{content}</p>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ):null
                }
              </div>
          </div>
        </section>
    </>
  )
}

export default ExpertiseLayout