import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PortfolioComponent = () => {
    const [bestFit, setBestFit] = useState([]);

    const pathName = window.location.pathname;
    const slugName = pathName.slice(1);

    const getApiData = async () => {
        if (!slugName) {
            const res = await axios.get('/pagecontent');
            setBestFit(res.data.recive_data[3]);
            // console.log(res.data.recive_data[5]);  
        } else {
            const res = await axios.get(`/pagecontent?page_slug=${slugName}`);
            setBestFit(res.data.recive_data[2]);
            // console.log(res.data.recive_data[5]);  
        }
    }
    useEffect(() => {
        getApiData();
    }, []);

    const data = bestFit.two_column_image_content;

    return (
        <section className={"two_colom_img_textSec margin-t-b portfolio_info_sec "+(data?(data.img_position_class):null)}>
            <div className="container">
                <h2>Portfolio</h2>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 img_div_sec">
                        {
                            data?(<img src={data.image_details.image_path} alt="" />):null
                        }
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 content_div_sec">
                        {
                            data?(<div className="" dangerouslySetInnerHTML={{__html: data.content}}></div>):null
                        }
                        <div>
                            <p className='mb-3'> <strong>Tools used</strong> </p>
                            <img src="./images/ai.png" />
                            <img src="./images/ps.png" />
                            <img src="./images/xd_logo.png" />
                            <img src="./images/wordpress.png" />
                        </div>
                    </div>                   
                </div>
            </div>
        </section>
    )
}

export default PortfolioComponent