import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Slider from "react-slick"; 

function BannerComponents() {
    const [banner, setBanner] = useState([]);
    const pathName = window.location.pathname;
    const slugArr = pathName.split('/');
    let slugName;
    if(slugArr.length == 2){
      slugName = pathName.slice(1);
    }else{
      let slugArr = pathName.split('/', 2);
      slugName = slugArr.slice(1);
    }

    const getBannerApiData = async () => {
      if (!slugName) {
        const res = await axios.get('/banner');
        setBanner(res.data.recive_data.banner_data[0]);
      } else {
        const res = await axios.get(`/banner?page_slug=${slugName}`);
        setBanner(res.data.recive_data.banner_data[0]);
      }
    }

    useEffect(() => {
      getBannerApiData();
    }, []);

    const bannerImg = banner.banner_image;
    const bannerBtn = banner.external_button_link;
    const popularBtn = banner.popular_button_links;
    var bannersetting = {
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      fade: true,
      speed: 3000,
    }
    return (
        <>
        {
        !slugName ? (
          <section className="siteBanner fontSiteBanner">
            <div className="siteBanner__slider">
              <div className='container'>
                <div className='row'>
                  <div className='col-sm-6 d-flex align-items-center'>
                    <div className="siteBanner__bannerOverlay">                    
                        <div className="bannerOverlay__center">
                          <h1 dangerouslySetInnerHTML={{__html: banner.title}}></h1>
                          <p dangerouslySetInnerHTML={{__html: banner.content}}></p>
                          {
                            bannerBtn?(
                              <NavLink to={'/' + bannerBtn[0].url}  className="rmbttn">{bannerBtn[0].title}</NavLink>
                            ):null
                          }
                          <div className='popularButton_section'>
                            <span>Popular:</span>
                            {
                              popularBtn?(
                                popularBtn.map( (post, index) => {
                                  const { popular_button } = post;
                                  const pbtnT = popular_button.title;
                                  return(
                                    pbtnT?( 
                                      <NavLink to={'/' + popular_button.url} className="popularBtn" key={index}>{ pbtnT }</NavLink> 
                                    ):null 
                                  )                                                            
                                })
                              ):null
                            }
                          </div>
                        </div>                     
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className="siteBanner__slider_item siteBanner__slider_item_home">
                      <Slider {...bannersetting}>                  
                      {
                        bannerImg?(
                          bannerImg.map( (post, index) => {
                            const {banner_img} = post;
                            return(
                              <div className='banner_slider_item' key={index}>
                                <picture>
                                  <source media="(min-width: 1024px)" srcSet={banner_img.desktop_banner_image} />
                                  <source media="(min-width: 768px)" srcSet={banner_img.tab_banner_image} />
                                  <source media="(min-width: 300px)" srcSet={banner_img.mobile_banner_image} />
                                  <img src={banner_img.desktop_banner_image} width="1920" height="930" alt="" />                         
                                </picture>
                              </div>
                            )                         
                          })                     
                        ):null
                      }
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) :(
          <section className="siteBanner">
            <div className="siteBanner__slider">
              <div className="siteBanner__slider_item">
                {
                    bannerImg?(<picture>
                          <source media="(min-width: 1024px)" srcSet={bannerImg.desktop_banner_image} />
                          <source media="(min-width: 768px)" srcSet={bannerImg.tab_banner_image} />
                          <source media="(min-width: 300px)" srcSet={bannerImg.mobile_banner_image} />
                          <img src={bannerImg.desktop_banner_image} width="1920" height="930" alt="" />
                        </picture>
                    ):null
                  }
                <div className="siteBanner__bannerOverlay inner_bannerOverlay__center d-flex align-items-center inner_bannerOverlay__center">
                  <div className="container">
                    <div className="bannerOverlay__center">
                      <h1 dangerouslySetInnerHTML={{__html: banner.title}}></h1>
                      <p dangerouslySetInnerHTML={{__html: banner.content}}></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>)
        }
      </>
    )
}

export default BannerComponents;