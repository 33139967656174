import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

function OurClientsComponent() {
    const [clientsdata, setClientsdata] = useState([]);
    const pathName = window.location.pathname;
    const slugArr = pathName.split('/');
    let slugName;
    if(slugArr.length == 2){
      slugName = pathName.slice(1);
    }else{
      let slugArr = pathName.split('/', 2);
      slugName = slugArr.slice(1);
    }

    const getApiData = async() =>{
        const res = await axios.get(`/ourclients?page_slug=${slugName}`);
        setClientsdata(res.data.recive_data.clients_data);
        console.log(res.data.recive_data.clients_data); 
    }
    useEffect( () => {
        getApiData();
    }, []);
    
    return (
        <section className="three_column_img_content_sec margin-t-b ourClients__section">
            <div className="container">
                <h2 className='text-center'>Our Clients</h2>
                <div className="row">
                    {
                        clientsdata?(
                            clientsdata.map( (post, index) => {
                                const { clients_logo, clients_projects, clients_projects_content, project_link } = post;
                                return(
                                    <div className="col-12 col-md-4 col-lg-4 padding_left_right" key={index}>
                                        <div className="card">
                                            <NavLink to={project_link}>
                                            {
                                                clients_logo?(<img src={clients_logo.logo_thumbnail} />):null
                                            }
                                            </NavLink>
                                            <div className="card-body">
                                                <NavLink to={project_link}><h4>{clients_projects}</h4></NavLink>
                                                <p>{clients_projects_content}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ):null
                    }
                </div>
            </div>
        </section>
    )
}

export default OurClientsComponent