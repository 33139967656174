import React, { useEffect, useState } from 'react';
import axios from 'axios';

function DesignCardComponents() {
    const [designcontent, setDesigncontent] = useState([]);
    // const [expertise, setExpertise] = useState([]);
    const pathName = window.location.pathname;
    const slugArr = pathName.split('/');
    let slugName;
    if(slugArr.length == 2){
      slugName = pathName.slice(1);
    }else{
      let slugArr = pathName.split('/', 2);
      slugName = slugArr.slice(1);
    }
    const getDesignApiData = async () => {
        let res = [];
        if (!slugName) {
          res = await axios.get('/pagecontent');
        } else {
          res = await axios.get(`/pagecontent?page_slug=${slugName}`);
        }
        setDesigncontent(res.data.recive_data[9].card_box);
        // console.log(res.data.recive_data[9].card_box); 
    }
    // console.log(designcontent); 
    useEffect(() => {
        getDesignApiData();
    }, []);
  return (
    <section className='designLogo__section'>
        <div className='container'>
            <h2 className='text-center'>Design for what you need</h2>
            <div className='row designCardBox_section'>
              {
                designcontent?(
                  designcontent.map( (post, index) => {
                    const { image_details, name, title, content, add_link } = post;
                    return(
                      <div className='col-sm-3 col-6 designCardBoxs' key={index}>
                        <div className='card'>
                          <div className='card-body'>
                              <div className='designImg'>
                                { image_details?(<img src={image_details.image_path} />):null }
                              </div>
                              <div className='designContent'>
                                { title?(<h4>{title}</h4>):null }
                                { name?(<h6>{name}</h6>):null }
                                { content?(<p>{content}</p>):null }
                              </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ):null
              }
            </div>
            <div className="btn_center"><a className="gg2btn" href="/expertise">Our Expertise</a></div>
        </div>
    </section>
  )
}

export default DesignCardComponents;