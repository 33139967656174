import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
// import Navbars from './Navbars';
import BannerComponents from '../components/BannerComponents';
import SeoComponents from '../components/SeoComponents';
import OurClientsComponent from '../components/OurClientsComponent';

const PortfolioLayout = () => {
  const [portfolio, setPortfolio] = useState([]);
  const [limit, setLimit] = useState(6);

  const getApiData = async () => {
    const res = await axios.get('/pagecontent?page_slug=portfolio');
    setPortfolio(res.data.recive_data[0]);
  }
  useEffect(() => {
    getApiData();
  }, []);

  const portfolioData = portfolio.card_box;
  // console.log(portfolioData); 

  let totalPage = portfolio.total_post;
  let pages = (totalPage - limit);
  const loadMore = () => {
    setLimit( (preVal) => preVal + 6);
  }
  return (
    <>
    <SeoComponents />
    <BannerComponents />
    <section className="portfolio_details_info_sec margin-t">
      <div className="container">
        <h2>No Matter What Service You're Looking For</h2>
        <p>We learn about you, research on your competitors, target your potential customers and then build a
          strategy.</p>

        <div className="row">
          {
            portfolioData ? (
              portfolioData.slice(0, limit).map((post, index) => {
                const { image_details, title, content, add_link } = post;
                return (
                  <div className="col-12 col-md-6 col-lg-4" key={index}>
                    <div className="card" >
                    <NavLink to={add_link}>
                      {
                        image_details?(<img src={image_details.image_path} alt="..." />):null
                      }
                      </NavLink>
                      <div className="card-body">
                        <h5 ><NavLink to={add_link}  >{title}</NavLink></h5>
                        <p >{content}</p>
                        <hr />
                        <ul>
                          <li><img src="./images/ps.png" alt="" /></li>
                          <li><img src="./images/ai.png" alt="" /></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : null
          }
        </div>
      </div>
      {
        (pages > 0)?(<div className="text-center prot_button_sec ">
        <div className="gg1btn" onClick={loadMore} >READ MORE</div>
        </div>):null
      }
    </section>
    <OurClientsComponent />
    </>
  )
}

export default PortfolioLayout;